import { useNavigation } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Headline, HelperText, Subheading } from 'react-native-paper';
import { Layout } from '../components/layout';
import { TextField } from '../components/text-field';

type Inputs = {
  email: string;
};

export const ForgotPasswordScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(data.email);
      navigate('ForgotPasswordSubmit', { email: data.email });
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline style={{ textAlign: 'center' }}>Forgot your password?</Headline>
      <Subheading style={{ textAlign: 'center', marginBottom: 8 }}>
        Let us know your email address and if it exists in our system, you
        should receive an email with a verification code.
      </Subheading>
      <TextField
        control={control}
        name='email'
        label='Email'
        autoComplete='email'
        rules={{
          required: { value: true, message: 'Please enter an email address' },
        }}
      />

      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        mode='contained'
        onPress={handleSubmit(onSubmit)}
        loading={loading}>
        Reset Password
      </Button>

      <Button onPress={() => navigate('SignIn')}>Back to sign in?</Button>
    </Layout>
  );
};
