import Constants from 'expo-constants';
import { useState } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  SubmitHandler,
  useForm,
  useFormState,
} from 'react-hook-form';
import {
  Button,
  Headline,
  HelperText,
  Paragraph,
  RadioButton,
  Text,
} from 'react-native-paper';
import { useApp } from '../../components/app-provider';
import { Layout } from '../../components/layout';

type Inputs = {
  priceId: string;
};

const PRICES = [
  {
    label: 'Annual plan - $20/year',
    value: Constants.manifest?.extra?.REACT_APP_PRICE_ANNUAL ?? 'annual',
  },
  {
    label: 'Monthly plan - $2/year',
    value: Constants.manifest?.extra?.REACT_APP_PRICE_MONTHLY ?? 'monthly',
  },
];

const SubscriptionChooser = <TFieldValues extends FieldValues>({
  ...controllerProps
}: Omit<ControllerProps<TFieldValues>, 'render'>) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value } }) => (
        <RadioButton.Group onValueChange={onChange} value={value}>
          {PRICES.map((price) => (
            <RadioButton.Item
              key={price.value}
              label={price.label}
              value={price.value}
            />
          ))}
        </RadioButton.Group>
      )}
    />
  );
};

export const BillingChooseModal: React.FC = () => {
  const { createCheckoutSession } = useApp();
  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      priceId: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isDirty } = useFormState({ control });

  const onSubmit: SubmitHandler<Inputs> = async ({ priceId }) => {
    if (!priceId) return;
    setLoading(true);
    try {
      await createCheckoutSession(priceId);
      return;
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline>You require a subscription to continue.</Headline>
      <Paragraph>Please choose a subscription plan.</Paragraph>
      <SubscriptionChooser control={control} name='priceId' />
      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        style={{ marginTop: 16 }}
        onPress={handleSubmit(onSubmit)}
        loading={loading}
        mode='contained'
        disabled={!isDirty}>
        Continue
      </Button>
    </Layout>
  );
};
