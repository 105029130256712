import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import { useApp } from '../../components/app-provider';
import { RootStackParamList } from '../../types';
import { LoadingScreen } from '../loading';

export const BillingSuccessModal: React.FC = () => {
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'BillingSuccess'>>();
  const { validateCheckoutSession } = useApp();

  useEffect(() => {
    if (params?.session_id) {
      validateCheckoutSession(params.session_id);
    }
  }, [params]);

  return <LoadingScreen />;
};
