import { useNavigation } from '@react-navigation/native';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { ScrollView, Pressable } from 'react-native';
import {
  Button,
  Headline,
  Paragraph,
  ProgressBar,
  Title,
} from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { Layout } from '../components/layout';
import { View } from '../components/Themed';
import { Timeline } from '../components/timeline';
import { UserAvatar } from '../components/user-avatar';
import { LoadingScreen } from './loading';

export const HomeScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { user, trips, listTrips } = useApp();

  useEffect(() => {
    listTrips();
  }, [user]);

  const handleAddTrip = () => {
    navigate('AddTripModal');
  };

  if (!user) {
    return <LoadingScreen />;
  }

  const entryDate = user.initialEntryDate
    ? DateTime.fromISO(user.initialEntryDate)
    : DateTime.local();
  const activationDate = user.activationDate
    ? DateTime.fromISO(user.activationDate)
    : DateTime.local();
  const eligibilityDate = user.eligibilityDate
    ? DateTime.fromISO(user.eligibilityDate)
    : DateTime.local();

  const totalDaysSinceActivation = Math.floor(
    DateTime.local().diff(activationDate, 'days').days
  );
  const totalDaysInCountry = totalDaysSinceActivation
    ? totalDaysSinceActivation - user.daysOutOfCountryAfterPR
    : 0;

  const totalDaysLeft = Math.max(
    Math.floor(eligibilityDate.diffNow('days').days),
    0
  );

  return (
    <Layout>
      <View style={{ flexDirection: 'row-reverse' }}>
        <Pressable onPress={() => navigate('Profile')}>
          <UserAvatar />
        </Pressable>
      </View>
      <ScrollView style={{ flex: 1 }}>
        <View>
          <Headline style={{ marginBottom: 16, marginTop: 8 }}>
            Hello{user?.givenName ? ` ${user.givenName}` : ''},
          </Headline>
          <Paragraph style={{ marginBottom: 16 }}>
            You have {totalDaysLeft} days left until you are eligible to apply
            for your citizenship.
          </Paragraph>
          <ProgressBar
            progress={totalDaysInCountry / 1095}
            style={{ marginBottom: 16 }}
          />
          <View style={{ flexDirection: 'row', marginBottom: 8 }}>
            <Title style={{ flex: 1 }}>Your timeline</Title>
          </View>
        </View>
        <Timeline
          trips={trips}
          entryDate={entryDate}
          activationDate={activationDate}
          eligibilityDate={eligibilityDate}
          onEventPress={({ id }) => navigate('EditTripModal', { tripId: id })}
        />
      </ScrollView>

      <Button
        mode='outlined'
        onPress={handleAddTrip}
        icon='plus-circle-outline'>
        Add Trip
      </Button>
    </Layout>
  );
};
