import { Avatar } from 'react-native-paper';
import { useApp } from './app-provider';

export const UserAvatar: React.FC = () => {
  const { user } = useApp();

  if (!user || !user.given_name || !user.family_name) {
    return <Avatar.Icon size={24} icon='account' />;
  }
  const initials =
    `${user?.given_name?.[0]}${user?.family_name?.[0]}`.toUpperCase();
  return <Avatar.Text size={24} label={initials} />;
};
