import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';
import { useApp } from '../components/app-provider';
import { AddActivationDateModal } from '../screens/add-activation-date-modal';

import { AddTripModal } from '../screens/add-trip-modal';
import { BillingChooseModal } from '../screens/billing/choose';
import { BillingSuccessModal } from '../screens/billing/success';
import { DeleteTripModal } from '../screens/delete-trip-modal';
import { ForgotPasswordScreen } from '../screens/forgot-password';
import { ForgotPasswordSubmitScreen } from '../screens/forgot-password-submit';
import { HomeScreen } from '../screens/home';
import { LoadingScreen } from '../screens/loading';
import NotFoundScreen from '../screens/NotFoundScreen';
import { ProfileScreen } from '../screens/profile';
import { SignInScreen } from '../screens/sign-in';
import { SignUpScreen } from '../screens/sign-up';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

const RootNavigator = () => {
  const { authLoading: loading, signedIn, user } = useApp();
  if (loading) return <LoadingScreen />;

  if (!signedIn)
    // If not signed in, tnen we only want to allow the user to see all the
    // authentication screens and should not even be allowed to see anything
    // else.
    return (
      <Stack.Navigator>
        <Stack.Screen
          name='SignIn'
          component={SignInScreen}
          options={{
            title: 'Sign in',
            // When logging out, a pop animation feels intuitive
            // You can remove this if you want the default 'push' animation
            animationTypeForReplace: !signedIn ? 'pop' : 'push',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name='SignUp'
          component={SignUpScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='ForgotPassword'
          component={ForgotPasswordScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='ForgotPasswordSubmit'
          component={ForgotPasswordSubmitScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );

  // If the user does not have an active subscription, allow them to choose one
  // and sign up
  if (user?.subscriptionStatus !== 'active') {
    // The user is signed in but does not have an active subscription.
    return (
      <Stack.Navigator>
        <Stack.Screen
          name='BillingChoose'
          component={BillingChooseModal}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='BillingSuccess'
          component={BillingSuccessModal}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }

  if (!user?.initialEntryDate || !user?.activationDate) {
    // The user is signed in but still doesn't have an activation date set
    return (
      <Stack.Navigator>
        <Stack.Screen
          name='ActivationDate'
          component={AddActivationDateModal}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }

  // User is now signed in, so they can now only see the app screens. And
  // should not be able to see the other screens
  return (
    <Stack.Navigator>
      <Stack.Screen
        name='Home'
        component={HomeScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name='Profile'
        component={ProfileScreen}
        options={{ title: 'Profile' }}
      />
      <Stack.Screen
        name='NotFound'
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name='AddTripModal'
          component={AddTripModal}
          options={{ headerTitle: 'Add a trip' }}
        />
        <Stack.Screen
          name='EditTripModal'
          component={AddTripModal}
          options={{ headerTitle: 'Edit a trip' }}
        />
        <Stack.Screen
          name='DeleteTripModal'
          component={DeleteTripModal}
          options={{ headerTitle: 'Delete trip' }}
        />
        <Stack.Screen
          name='ActivationDate'
          component={AddActivationDateModal}
          options={{ headerTitle: 'Edit PR dates' }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};
