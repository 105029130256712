import { API, Auth } from 'aws-amplify';
import { registerRootComponent } from 'expo';
import Constants from 'expo-constants';
import { en, enGB, registerTranslation } from 'react-native-paper-dates';
import * as Sentry from 'sentry-expo';
import App from './App';

Sentry.init({
  dsn: Constants.manifest?.extra?.REACT_APP_SENTRY_DSN,
  tracesSampleRate:
    Constants.manifest?.extra?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

Auth.configure({
  region: Constants.manifest?.extra?.REACT_APP_AWS_REGION,
  userPoolId: Constants.manifest?.extra?.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId:
    Constants.manifest?.extra?.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  cookieStorage: {
    domain:
      process.env.NODE_ENV !== 'development'
        ? Constants.manifest?.extra?.REACT_APP_COGNITO_DOMAIN
        : 'localhost',
    path: '/',
    expires: 30,
    secure: process.env.NODE_ENV !== 'development',
  },
});

API.configure({
  endpoints: [
    {
      name: 'api',
      endpoint: Constants.manifest?.extra?.REACT_APP_API_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
});

registerTranslation('en', en);
registerTranslation('en-GB', enGB);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App as any);
