import { Action, AppState } from './interfaces';

export const DEFAULT_STATE: AppState = {
  authLoading: true,
  signedIn: false,
  user: null,
  trips: [],
};

export const reducer = (
  state: AppState = DEFAULT_STATE,
  action: Action
): AppState => {
  switch (action.type) {
    case 'SET_AUTH_LOADING':
      return {
        ...state,
        authLoading: action.payload,
      };
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        user: action.payload,
        signedIn: !!action.payload,
        trips: !!action.payload ? state.trips : [],
      };
    case 'ADD_TRIP':
      return {
        ...state,
        trips: [...state.trips, action.payload],
      };
    case 'UPDATE_TRIP':
      const tripToUpdateIndex = state.trips.findIndex(
        (val) => val.id === action.payload.id
      );

      // If the trip is not found, return the current state
      if (tripToUpdateIndex === -1) return state;

      // Otherwise, update the trip
      const newTrips = [...state.trips];
      newTrips[tripToUpdateIndex] = action.payload;
      return { ...state, trips: newTrips };
    case 'LIST_TRIPS':
      return {
        ...state,
        trips: action.payload,
      };
    default:
      return state;
  }
};
