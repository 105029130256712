import { DateTime } from 'luxon';

/**
 * Ensure that the provided date is a valid DateTime
 * object. If not, return a default DateTime object.
 * @param date The date to validate
 * @returns a valid DateTime object
 */
export const ensureDateTime = (date: DateTime | any): DateTime => {
  if (date instanceof DateTime) {
    return date;
  }
  return DateTime.fromObject((date as any).c);
};
