import { useNavigation } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Headline, HelperText, Subheading } from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { Layout } from '../components/layout';
import { TextField } from '../components/text-field';

type Inputs = {
  givenName: string;
  familyName: string;
  email: string;
  password: string;
};

export const SignUpScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { signUp } = useApp();
  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      givenName: '',
      familyName: '',
      email: '',
      password: '',
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      await signUp(data);
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline style={{ textAlign: 'center' }}>Sign up to Beaco</Headline>
      <Subheading style={{ textAlign: 'center', marginBottom: 8 }}>
        Create a new account by letting us know your name, email address and
        password you'd like to use to sign into Beaco.
      </Subheading>
      <TextField
        control={control}
        name='givenName'
        label='Given Name'
        autoComplete='off'
        rules={{
          required: { value: true, message: 'Please enter your given name' },
        }}
      />
      <TextField
        control={control}
        name='familyName'
        label='Family Name'
        autoComplete='off'
        rules={{
          required: { value: true, message: 'Please enter your family name' },
        }}
      />
      <TextField
        control={control}
        name='email'
        label='Email'
        autoComplete='email'
        rules={{
          required: { value: true, message: 'Please enter an email address' },
        }}
      />

      <TextField
        control={control}
        name='password'
        label='Password'
        autoComplete='password-new'
        secureTextEntry
        rules={{
          required: { value: true, message: 'Please enter a password' },
        }}
      />

      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        mode='contained'
        onPress={handleSubmit(onSubmit)}
        loading={loading}>
        Sign Up
      </Button>

      <Button onPress={() => navigate('SignIn')}>Back to sign in?</Button>
    </Layout>
  );
};
