import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import lookup from 'country-code-lookup';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Button, HelperText, Title } from 'react-native-paper';
import { useTrip } from '../components/app-provider';
import { Layout } from '../components/layout';
import { RootStackParamList } from '../types';

export const DeleteTripModal: React.FC = () => {
  const {
    params: { tripId },
  } = useRoute<RouteProp<RootStackParamList, 'DeleteTripModal'>>();
  const { deleteTrip, trip } = useTrip(tripId);
  const { navigate } = useNavigation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await deleteTrip(tripId);
      return navigate('Home');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('Home');
  };

  const country =
    trip?.destination?.length === 2
      ? lookup.byIso(trip.destination)?.country
      : trip?.destination || 'N/A';

  return (
    <Layout>
      <Title>
        Are you sure you would like to delete your trip to {country} on{' '}
        {trip?.departureDate
          ? DateTime.fromISO(trip?.departureDate).toLocaleString(
              DateTime.DATE_MED
            )
          : 'unknown date'}
        ?
      </Title>
      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>
      <Button
        mode='contained'
        style={{ marginVertical: 12 }}
        onPress={handleConfirm}
        loading={loading}>
        Yes, delete the trip
      </Button>
      <Button mode='outlined' onPress={handleCancel}>
        No, go back to home screen
      </Button>
    </Layout>
  );
};
