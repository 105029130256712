import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import { TextInputProps } from 'react-native';
import { HelperText, TextInput } from 'react-native-paper';

type Props = {
  label: string;
  autoComplete?: unknown;
  secureTextEntry?: boolean;
} & Pick<TextInputProps, 'multiline' | 'numberOfLines' | 'style'>;

export const TextField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  label,
  autoComplete,
  secureTextEntry,
  multiline,
  numberOfLines,
  style,
  ...controllerProps
}: Omit<ControllerProps<TFieldValues, TName>, 'render'> & Props) => {
  return (
    <Controller
      {...controllerProps}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <>
          <TextInput
            label={label}
            value={value}
            onChangeText={onChange}
            onBlur={onBlur}
            mode='outlined'
            autoComplete={autoComplete}
            secureTextEntry={secureTextEntry}
            error={!!error}
            multiline={multiline}
            numberOfLines={numberOfLines}
            style={style}
          />
          <HelperText
            type='error'
            visible={!!error}
            onPressIn={() => {}}
            onPressOut={() => {}}>
            {error?.message}
          </HelperText>
        </>
      )}
    />
  );
};
