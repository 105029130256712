import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { Platform } from 'react-native';
import { Button, HelperText, Title } from 'react-native-paper';
import { useTrip } from '../components/app-provider';
import { CountryField } from '../components/country-field';
import { DateField } from '../components/date-field';
import { Layout } from '../components/layout';
import { TextField } from '../components/text-field';
import { ensureDateTime } from '../components/utils';
import { RootStackParamList } from '../types';

type Inputs = {
  departureDate: DateTime | undefined;
  returnDate: DateTime | undefined;
  destination: string;
  reason: string;
};

export const AddTripModal: React.FC = () => {
  const { navigate } = useNavigation();
  const { params } = useRoute<RouteProp<RootStackParamList, 'EditTripModal'>>();
  const tripId = params?.tripId;
  const { saveTrip, user, trip } = useTrip(tripId);

  const { control, handleSubmit, getValues, watch, setValue } = useForm<Inputs>(
    {
      defaultValues: {
        departureDate: trip?.departureDate
          ? DateTime.fromISO(trip.departureDate)
          : undefined,
        returnDate: trip?.returnDate
          ? DateTime.fromISO(trip.returnDate)
          : undefined,
        destination: trip?.destination || '',
        reason: trip?.reason || '',
      },
    }
  );

  const { isDirty } = useFormState({ control });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (trip && !isDirty) {
      setValue('departureDate', DateTime.fromISO(trip.departureDate));
      setValue('returnDate', DateTime.fromISO(trip.returnDate));
      setValue('destination', trip.destination);
      setValue('reason', trip.reason);
    }
  }, [trip]);

  if (tripId && !trip) {
    return (
      <Layout>
        <Title>Unable to find the trip...</Title>
      </Layout>
    );
  }

  const onSubmit: SubmitHandler<Inputs> = async ({
    departureDate,
    returnDate,
    destination,
    reason,
  }) => {
    setLoading(true);
    try {
      // For some reason date is not a DateTime object but a jsonified object of DateTime
      const departureDateToUse = ensureDateTime(departureDate);
      const returnDateToUse = ensureDateTime(returnDate);

      await saveTrip({
        departureDate: departureDateToUse,
        returnDate: returnDateToUse,
        destination,
        reason,
      });
      setErrorMessage('');
      setLoading(false);
      return navigate('Home');
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  const handleDelete = () => {
    navigate('DeleteTripModal', { tripId });
  };

  const departureDate = watch('departureDate');
  const minDateToUse = user?.initialEntryDate || user?.activationDate;
  return (
    <Layout>
      <DateField
        label='Departure date'
        control={control}
        name='departureDate'
        rules={{
          required: { value: true, message: 'Please enter a departure date' },
        }}
        minDate={minDateToUse ? DateTime.fromISO(minDateToUse) : undefined}
      />
      <DateField
        label='Return date'
        control={control}
        name='returnDate'
        rules={{
          required: { value: true, message: 'Please enter a return date' },
          validate: (value) => {
            const departureDate = ensureDateTime(getValues('departureDate'));
            if (departureDate && value && departureDate <= value) return;
            return 'Please enter a return date after the departure date';
          },
        }}
        minDate={departureDate}
      />
      <CountryField
        label='Destination'
        control={control}
        name='destination'
        rules={{
          required: {
            value: true,
            message: 'Please enter a destination for this trip',
          },
        }}
      />
      <TextField
        label='Reason'
        control={control}
        name='reason'
        multiline={true}
        numberOfLines={4}
        style={{ height: Platform.OS === 'ios' ? 100 : undefined }}
        rules={{
          required: {
            value: true,
            message: 'Please enter a reason for this trip',
          },
        }}
      />
      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>
      <Button
        mode='contained'
        loading={loading}
        style={{ marginVertical: 12 }}
        onPress={handleSubmit(onSubmit)}>
        {tripId ? 'Update Trip' : 'Add Trip'}
      </Button>
      {tripId && (
        <Button
          mode='outlined'
          loading={loading}
          style={{ marginVertical: 12 }}
          onPress={handleDelete}>
          Delete Trip
        </Button>
      )}
    </Layout>
  );
};
