import { RouteProp, useRoute } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Headline, HelperText, Subheading } from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { Layout } from '../components/layout';
import { TextField } from '../components/text-field';
import { RootStackParamList } from '../types';

type Inputs = {
  email: string;
  code: string;
  password: string;
};

export const ForgotPasswordSubmitScreen: React.FC = () => {
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'ForgotPasswordSubmit'>>();
  const { signIn } = useApp();

  const { control, handleSubmit, setValue } = useForm<Inputs>({
    defaultValues: {
      email: '',
      code: '',
      password: '',
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (params?.email) {
      setValue('email', params.email);
    }
  }, [params]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
      // Automatically log them in using the new password
      await signIn(data.email, data.password);
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline style={{ textAlign: 'center' }}>
        Verify the code sent to your email
      </Headline>
      <Subheading style={{ textAlign: 'center', marginBottom: 8 }}>
        Please check your Spam folder if you don't see the email.
      </Subheading>
      <TextField
        control={control}
        name='email'
        label='Email'
        autoComplete='email'
        rules={{
          required: { value: true, message: 'Please enter an email address' },
        }}
      />

      <TextField
        control={control}
        name='code'
        label='Verification Code'
        autoComplete='off'
        rules={{
          required: {
            value: true,
            message: 'Please enter the verifcation code',
          },
        }}
      />

      <TextField
        control={control}
        name='password'
        label='New Password'
        autoComplete='password-new'
        secureTextEntry
        rules={{
          required: { value: true, message: 'Please enter a new password' },
        }}
      />

      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        mode='contained'
        onPress={handleSubmit(onSubmit)}
        loading={loading}>
        Set new password
      </Button>
    </Layout>
  );
};
