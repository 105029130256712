import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Headline, HelperText, Subheading } from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { Layout } from '../components/layout';
import { TextField } from '../components/text-field';

type Inputs = {
  email: string;
  password: string;
};

export const SignInScreen: React.FC = () => {
  const { navigate } = useNavigation();
  const { signIn } = useApp();
  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    try {
      await signIn(data.email, data.password);
      return;
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline style={{ textAlign: 'center' }}>Welcome to Beaco!</Headline>
      <Subheading style={{ textAlign: 'center', marginBottom: 8 }}>
        Sign in today, to start tracking your trips
      </Subheading>
      <TextField
        control={control}
        name='email'
        label='Email'
        autoComplete='email'
        rules={{
          required: { value: true, message: 'Please enter an email address' },
        }}
      />

      <TextField
        control={control}
        name='password'
        label='Password'
        autoComplete='password'
        secureTextEntry
        rules={{
          required: { value: true, message: 'Please enter a password' },
        }}
      />

      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        mode='contained'
        onPress={handleSubmit(onSubmit)}
        loading={loading}>
        Sign In
      </Button>

      <Button onPress={() => navigate('ForgotPassword')}>
        Forgot password?
      </Button>
      <Button onPress={() => navigate('SignUp')}>
        Or sign up for a new account?
      </Button>
    </Layout>
  );
};
