import { ActivityIndicator, useTheme } from 'react-native-paper';
import { View } from '../components/Themed';

/**
 * A loading component centered in the page
 */
export const LoadingScreen: React.FC = () => {
  const theme = useTheme();
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
      }}>
      <ActivityIndicator
        style={{ marginBottom: 5 }}
        size={30}
        color={theme.colors.primary}
      />
    </View>
  );
};
