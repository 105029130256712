import { RouteProp, useRoute } from '@react-navigation/native';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Headline, HelperText, Paragraph } from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { DateField } from '../components/date-field';
import { Layout } from '../components/layout';
import { ensureDateTime } from '../components/utils';
import { RootStackParamList, RootStackScreenProps } from '../types';

type Inputs = {
  entryDate: DateTime | undefined;
  activationDate: DateTime | undefined;
};

export const AddActivationDateModal: React.FC<
  RootStackScreenProps<'ActivationDate'>
> = ({ navigation: { navigate } }) => {
  const { params } =
    useRoute<RouteProp<RootStackParamList, 'ActivationDate'>>();

  const { user, updateActivationDate } = useApp();
  const { control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      entryDate: user?.initialEntryDate
        ? DateTime.fromISO(user.initialEntryDate)
        : undefined,
      activationDate: user?.activationDate
        ? DateTime.fromISO(user.activationDate)
        : undefined,
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit: SubmitHandler<Inputs> = async ({
    entryDate,
    activationDate,
  }) => {
    if (!activationDate || !entryDate) return;
    setLoading(true);
    try {
      // For some reason date is not a DateTime object but a jsonified object of DateTime
      await updateActivationDate(
        ensureDateTime(entryDate),
        ensureDateTime(activationDate)
      );

      if (params?.navigateAfterSave) {
        navigate('Profile');
      } else {
        navigate('Home');
      }
      return;
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Headline>Set your PR Dates</Headline>
      <Paragraph>
        This is the date that you entered Canada as a temporary resident. If you
        never entered Canada, before you activated your PR, please enter the
        same date as your PR.
      </Paragraph>
      <DateField
        control={control}
        name='entryDate'
        label='Entry Date'
        rules={{
          required: { value: true, message: 'Please enter an entry date' },
        }}
      />
      <Paragraph>
        This is the date that you activated your Permanent Residence. We need
        this to perform our calculation on how many day(s) you have spent in
        Canada.
      </Paragraph>
      <DateField
        control={control}
        name='activationDate'
        label='Activation Date'
        rules={{
          required: { value: true, message: 'Please enter an activation date' },
        }}
      />
      <HelperText
        type='error'
        visible={!!errorMessage}
        onPressIn={() => {}}
        onPressOut={() => {}}>
        {errorMessage}
      </HelperText>

      <Button
        onPress={handleSubmit(onSubmit)}
        loading={loading}
        mode='contained'>
        Save activation date
      </Button>
    </Layout>
  );
};
