import { API, Auth } from 'aws-amplify';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useReducer } from 'react';
import { Context } from './context';
import { AddTripParams, SignUpParams } from './interfaces';
import { DEFAULT_STATE, reducer } from './reducers';

/**
 * An App provider to provide the state required for the app. Including whether
 * or not the user is signed in.
 */
export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  // This is to see if the user is already logged in on initial load and then
  // set the state as required if they are
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        // The user is signed in and authenticated
        await getUserProfile();
        await listTrips();
      })
      .catch(() => {
        // Something went wrong in getting the current user
        dispatch({ type: 'UPDATE_USER_PROFILE', payload: null });
      })
      .finally(() => {
        dispatch({ type: 'SET_AUTH_LOADING', payload: false });
      });
  }, []);

  const getUserProfile = useCallback(async () => {
    // And then get the user profile from the backend
    const res = await API.get('api', '/users', {});
    // Then set it in the auth state
    dispatch({ type: 'UPDATE_USER_PROFILE', payload: res.data });
  }, []);

  const signIn = useCallback(async (username: string, password: string) => {
    // Sign in
    await Auth.signIn(username, password);
    await getUserProfile();
  }, []);

  const signOut = useCallback(() => {
    dispatch({ type: 'UPDATE_USER_PROFILE', payload: null });
    Auth.signOut();
  }, []);

  const signUp = useCallback(async (params: SignUpParams) => {
    await Auth.signUp({
      username: params.email,
      password: params.password,
      attributes: {
        email: params.email,
        given_name: params.givenName,
        family_name: params.familyName,
      },
    });

    await signIn(params.email, params.password);
  }, []);

  const updateActivationDate = useCallback(
    async (entryDate: DateTime, activationDate: DateTime) => {
      const res = await API.post('api', '/users/activation-date', {
        body: {
          entryDate: entryDate.toISODate(),
          activationDate: activationDate.toISODate(),
        },
      });
      dispatch({ type: 'UPDATE_USER_PROFILE', payload: res.data });
    },
    []
  );

  const addTrip = useCallback(async (tripParams: AddTripParams) => {
    const res = await API.post('api', '/trips', {
      body: {
        ...tripParams,
        departureDate: tripParams.departureDate.toISODate(),
        returnDate: tripParams.returnDate.toISODate(),
      },
    });

    dispatch({ type: 'ADD_TRIP', payload: res.data });

    await getUserProfile();
  }, []);

  const updateTrip = useCallback(
    async (tripId: string, tripParams: AddTripParams) => {
      const res = await API.put('api', `/trips/${tripId}`, {
        body: {
          ...tripParams,
          departureDate: tripParams.departureDate.toISODate(),
          returnDate: tripParams.returnDate.toISODate(),
        },
      });

      dispatch({ type: 'UPDATE_TRIP', payload: res.data });

      await getUserProfile();
    },
    []
  );

  const deleteTrip = useCallback(async (tripId: string) => {
    const res = await API.del('api', `/trips/${tripId}`, {});
    if (res.data.trips) {
      dispatch({ type: 'LIST_TRIPS', payload: res.data.trips });
    }

    if (res.data.user) {
      dispatch({ type: 'UPDATE_USER_PROFILE', payload: res.data.user });
    } else {
      await getUserProfile();
    }
  }, []);

  const listTrips = useCallback(async () => {
    const res = await API.get('api', '/trips', {});
    dispatch({ type: 'LIST_TRIPS', payload: res.data });
  }, []);

  const createCheckoutSession = useCallback(async (priceId: string) => {
    const res = await API.post('api', '/billing/checkout-sessions', {
      body: { priceId },
    });

    window.location.href = res.data.url;
    return;
  }, []);

  const validateCheckoutSession = useCallback(async (sessionId: string) => {
    const res = await API.post('api', '/billing/checkout-sessions/validate', {
      body: { sessionId },
    });

    if (res.data) {
      dispatch({ type: 'UPDATE_USER_PROFILE', payload: res.data });
    }
  }, []);

  const getBillingPortal = useCallback(async () => {
    const res = await API.get('api', '/billing/portal', {});

    window.location.href = res.data.url;
    return;
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        signIn,
        signOut,
        signUp,
        updateActivationDate,
        addTrip,
        updateTrip,
        deleteTrip,
        listTrips,
        createCheckoutSession,
        validateCheckoutSession,
        getBillingPortal,
      }}>
      {children}
    </Context.Provider>
  );
};

// Export hooks for use by other components
export { useApp, useTrip } from './context';
