import { DateTime } from 'luxon';
import { createContext, useContext } from 'react';
import { AddTripParams, AppState, SignUpParams } from './interfaces';
import { DEFAULT_STATE } from './reducers';

export type AppContext = AppState & {
  signOut: () => void;
  signIn: (username: string, password: string) => Promise<void>;
  signUp: (params: SignUpParams) => Promise<void>;
  updateActivationDate: (
    entryDate: DateTime,
    activationDate: DateTime
  ) => Promise<void>;
  addTrip: (trip: AddTripParams) => Promise<void>;
  updateTrip: (tripId: string, trip: AddTripParams) => Promise<void>;
  listTrips: () => Promise<void>;
  deleteTrip: (tripId: string) => Promise<void>;
  createCheckoutSession: (priceId: string) => Promise<void>;
  validateCheckoutSession: (sessionId: string) => Promise<void>;
  getBillingPortal: () => Promise<void>;
};

export const DEFAULT_APP_CONTEXT: AppContext = {
  ...DEFAULT_STATE,
  signIn: async () => {},
  signOut: () => {},
  signUp: async () => {},
  updateActivationDate: async () => {},
  addTrip: async () => {},
  updateTrip: async () => {},
  listTrips: async () => {},
  deleteTrip: async () => {},
  createCheckoutSession: async () => {},
  validateCheckoutSession: async () => {},
  getBillingPortal: async () => {},
};

export const Context = createContext<AppContext>(DEFAULT_APP_CONTEXT);

export const useApp = () => useContext<AppContext>(Context);

export const useTrip = (tripId?: string) => {
  const { user, addTrip, updateTrip, deleteTrip, trips } = useApp();
  const trip = tripId ? trips.find((t) => t.id === tripId) : undefined;

  const saveTrip = async (trip: AddTripParams) => {
    if (!tripId) {
      // No tripId means we are creating a new trip
      await addTrip(trip);
    } else {
      // Otherwise, update the trip
      await updateTrip(tripId, trip);
    }
  };
  return {
    user,
    saveTrip,
    deleteTrip,
    trip,
  };
};
