import { min } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import { Pressable, TextStyle, View, ViewStyle } from 'react-native';
import { Caption, HelperText, Paragraph, useTheme } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { ensureDateTime } from './utils';

type Props = {
  label: string;
  autoComplete?: unknown;
  secureTextEntry?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
};

const useFakeInputStyle = (
  disabled: boolean = false,
  error: boolean = false
): { input: ViewStyle; text: TextStyle; label: TextStyle } => {
  const theme = useTheme();
  return {
    input: {
      minHeight: 56,
      borderWidth: 1,
      borderRadius: theme.roundness,
      borderColor: disabled
        ? theme.colors.disabled
        : error
        ? theme.colors.error
        : theme.colors.placeholder,
      backgroundColor: disabled ? '' : theme.colors.background,
      paddingHorizontal: 14,
      paddingVertical: 4,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 8,
    },
    text: {
      fontSize: 16,
      paddingVertical: 5,
      color: disabled
        ? theme.colors.disabled
        : error
        ? theme.colors.error
        : theme.colors.text,
    },
    label: {
      position: 'absolute',
      top: -14,
      backgroundColor: theme.colors.background,
      paddingHorizontal: 4,
    },
  };
};

const DateInput: React.FC<{
  label: string;
  value?: DateTime;
  onChange?: (val: DateTime) => void;
  disabled?: boolean;
  error?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
}> = ({ label, value, onChange = () => {}, disabled, error, minDate }) => {
  const fakeStyles = useFakeInputStyle(disabled, error);

  const [date, setDate] = useState<DateTime | undefined>(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (minDate && date && date < minDate) {
      setDate(minDate);
    }
  }, [minDate]);

  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      const dateToUse = DateTime.fromJSDate(params.date);
      setDate(dateToUse);
      onChange(dateToUse);
    },
    [setOpen, setDate]
  );

  return (
    <>
      <Pressable onPress={() => setOpen(true)}>
        <View style={fakeStyles.input}>
          <Caption style={fakeStyles.label}>{label}</Caption>
          <Paragraph style={fakeStyles.text}>
            {date?.toLocaleString(DateTime.DATE_MED) || 'Tap to enter date'}
          </Paragraph>
        </View>
      </Pressable>
      <DatePickerModal
        locale='en'
        mode='single'
        visible={open}
        onDismiss={onDismissSingle}
        date={date?.toJSDate()}
        onConfirm={onConfirmSingle}
        uppercase={false} // optional, default is true
        validRange={{
          startDate: minDate?.startOf('day').toJSDate(), // optional
        }}
      />
    </>
  );
};

/**
 * An input field to set a date with
 */
export const DateField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  label,
  autoComplete,
  secureTextEntry,
  minDate,
  maxDate,
  ...controllerProps
}: Omit<ControllerProps<TFieldValues, TName>, 'render'> & Props) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DateInput
            label={label}
            onChange={onChange as any}
            value={value ? ensureDateTime(value) : value}
            error={Boolean(error)}
            minDate={minDate}
            maxDate={maxDate}
          />
          <HelperText
            type='error'
            visible={!!error}
            onPressIn={() => {}}
            onPressOut={() => {}}>
            {error?.message}
          </HelperText>
        </>
      )}
    />
  );
};
