import lookup from 'country-code-lookup';
import { DateTime } from 'luxon';
import { useTheme } from 'react-native-paper';
import TimelineComponent from 'react-native-timeline-flatlist';
import type { Trip } from '../../../../src';

const getTripsBefore = (
  trips: Trip[],
  entryDate: DateTime,
  activationDate: DateTime
) =>
  trips.filter(
    (val) =>
      DateTime.fromISO(val.departureDate) >= entryDate &&
      DateTime.fromISO(val.departureDate) < activationDate
  );

const getTripsAfter = (trips: Trip[], activationDate: DateTime) =>
  trips.filter((val) => DateTime.fromISO(val.departureDate) >= activationDate);

const convertTrip = (trips: Trip[]) =>
  trips.map((trip) => {
    const departureDate = DateTime.fromISO(trip.departureDate);
    const returnDate = DateTime.fromISO(trip.returnDate);
    const daysInBetween = returnDate.diff(departureDate, 'days').days + 1;
    const country =
      trip.destination.length === 2
        ? lookup.byIso(trip.destination)?.country || 'N/A'
        : trip.destination;
    return {
      id: trip.id,
      time: departureDate.toLocaleString(DateTime.DATE_MED),
      title: `${country} (${daysInBetween} days)`,
      description: trip.reason,
    };
  });

export const Timeline: React.FC<{
  trips: Trip[];
  entryDate: DateTime;
  activationDate: DateTime;
  eligibilityDate: DateTime;
  onEventPress?: (trip: Trip) => void;
}> = ({ trips, entryDate, activationDate, eligibilityDate, onEventPress }) => {
  const theme = useTheme();

  const tripsBefore = convertTrip(
    getTripsBefore(trips, entryDate, activationDate)
  );
  const tripsAfter = convertTrip(getTripsAfter(trips, activationDate));

  const data = [
    ...(entryDate !== activationDate
      ? [
          {
            id: 'entry',
            time: entryDate.toLocaleString(DateTime.DATE_MED),
            title: 'Entry date',
          },
          ...tripsBefore,
        ]
      : []),
    {
      id: 'activation',
      time: activationDate.toLocaleString(DateTime.DATE_MED),
      title: 'PR Activated',
    },
    ...tripsAfter,
    {
      id: 'eligibility',
      time: eligibilityDate.toLocaleString(DateTime.DATE_MED),
      title: 'Eligible to apply for Citizenship',
    },
  ];

  const handleEventPress = (event: Event) => {
    const trip = trips.find((trip) => trip.id === (event as any).id);
    if (trip && onEventPress) {
      onEventPress(trip);
    }
  };

  return (
    <TimelineComponent
      data={data}
      timeStyle={{ color: theme.colors.text }}
      timeContainerStyle={{ minWidth: 90 }}
      titleStyle={{ color: theme.colors.text }}
      descriptionStyle={{ color: theme.colors.text }}
      detailContainerStyle={{ marginTop: -12 }}
      onEventPress={handleEventPress}
    />
  );
};
