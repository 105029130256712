/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      SignIn: 'sign-in',
      SignUp: 'sign-up',
      ForgotPassword: 'forgot-password',
      ForgotPasswordSubmit: 'forgot-password/submit',
      Home: '',
      Profile: 'profile',
      ActivationDate: 'activation-date',
      BillingChoose: 'billing/choose',
      BillingSuccess: 'billing/success',
      AddTripModal: 'trips/create',
      EditTripModal: 'trips/:tripId',
      DeleteTripModal: 'trips/:tripId/delete',
      NotFound: '*',
    },
  },
};

export default linking;
