import lookup from 'country-code-lookup';
import { useState } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import { HelperText } from 'react-native-paper';
import DropDown from 'react-native-paper-dropdown';

const countryList = lookup.countries.map((val) => ({
  value: val.iso2,
  label: val.country,
}));

type Props = {
  label: string;
};

export const CountryField = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  label,
  ...controllerProps
}: Omit<ControllerProps<TFieldValues, TName>, 'render'> & Props) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DropDown
            label={label}
            mode='outlined'
            visible={showDropDown}
            showDropDown={() => setShowDropDown(true)}
            onDismiss={() => setShowDropDown(false)}
            value={value}
            setValue={onChange}
            list={countryList}
            inputProps={{
              error: !!error,
            }}
          />
          <HelperText
            type='error'
            visible={!!error}
            onPressIn={() => {}}
            onPressOut={() => {}}>
            {error?.message}
          </HelperText>
        </>
      )}
    />
  );
};
