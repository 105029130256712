import { ScrollView } from 'react-native';
import { Button, Divider, List } from 'react-native-paper';
import { useApp } from '../components/app-provider';
import { Layout } from '../components/layout';
import { RootStackScreenProps } from '../types';

export const ProfileScreen: React.FC<RootStackScreenProps<'Profile'>> = ({
  navigation: { navigate },
}) => {
  const { signOut, user, getBillingPortal } = useApp();

  return (
    <Layout>
      <ScrollView style={{ flex: 1 }}>
        <List.Section>
          <List.Subheader>Profile</List.Subheader>
          <List.Item
            title={
              user?.givenName || user?.familyName
                ? `${user?.givenName} ${user?.familyName}`
                : 'No name specifed'
            }
          />
          <List.Item
            title='PR Dates'
            description='Click to update your activation dates'
            onPress={() =>
              navigate('ActivationDate', { navigateAfterSave: true })
            }
            right={(props) => <List.Icon {...props} icon='chevron-right' />}
          />
        </List.Section>
        <Divider />
        <List.Section>
          <List.Subheader>Billing</List.Subheader>
          <List.Item
            title='Update billing settings'
            onPress={() => getBillingPortal()}
            right={(props) => <List.Icon {...props} icon='chevron-right' />}
          />
        </List.Section>
      </ScrollView>
      <Button mode='outlined' onPress={signOut}>
        Sign out
      </Button>
    </Layout>
  );
};
